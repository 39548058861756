body {
  margin: 0;
  font-family: neue-haas-grotesk-display, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #efefef;
  --gray: #dddddd;
  --gray2: #999999;
  --gray3: #666666;
  --gray4: #2E2E2E;
  --white: #efefef;
  --black: #121212;
  --cyan: #438893;
  --blue: #7a78bf;
  --red: #db4d3d;
}

p, a {
  color: var(--black);
  font-size: 20px;
  line-height: 24px;
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: 400;
  color: var(--black);
  padding: 0px;
  margin: 0px;
  font-size: 33px;
}

h2 {
  font-weight: 400;
  color: var(--black);
  font-size: 24px;
  line-height: 32px;
  padding: 0px;
  margin: 0px;
}

.small-text {
  font-size: 16px;
  line-height: 20px;
}