.Renderer-container {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

#render {
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

}

#edgeRender {
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.Renderer-circle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 128px;
  width: 128px;
  background-color: var(--gray);
  border-radius: 10000px;
}

.Renderer-circle p {
  user-select: none;
}