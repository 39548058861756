/* Root renderer of the app. */
/* Since the app doesn't have routing, we can style this */
/* to take up the entire viewport */
.App {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100vw;
  height: 100vh;

  background-color: var(--background);
}

.App-absolute {
  position: fixed;
  bottom: 32px;
  left: 32px;

  z-index: 2;
}